import {Grid} from "@material-ui/core";
import ProfileSecondaryImage from "./ProfileSecondaryImage";
import * as React from "react";
import {faCheck, faCertificate} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import OpenChat from "./OpenChat";
import {splitAboutMePrismic} from "../lib/structure_data";
import '../styles/about_me.scss';
import '../styles/general.scss';

const MainPresentationLargeBox = (props) => {

    const {homepageData, prismicAboutme} = props

    const {
        acreditationsItems,
        professionalFormationsItems,
        psihoBaseFormationsItems,
        otherPsihoterapeutForamtionsItems,
        coupleFamilyItems,
        autistItems,
        otherItems
    } = splitAboutMePrismic(prismicAboutme)

    const aboutMeData = [
        {
            'title': 'Acreditari',
            'prismic': acreditationsItems
        },
        {
            'title': 'Inregistrari profesionale',
            'prismic': professionalFormationsItems
        },
        {
            'title': 'Formare de baza ca psiholog',
            'prismic': psihoBaseFormationsItems
        },
        {
            'title': 'Alte formari ca psihoterapeut',
            'prismic': otherPsihoterapeutForamtionsItems
        },
        {
            'title': 'Formare de baza ca psihoterapeut in tulburari de spectru autist',
            'prismic': autistItems
        },
        {
            'title': 'Formare de baza ca psihoterapeut de cuplu si familie sistemica',
            'prismic': coupleFamilyItems
        },
        {
            'title': 'Alte formari profesionale',
            'prismic': otherItems
        }
    ]

    return (
        <React.Fragment>
            <Grid item sm={12} xl={12} lg={12} md={12} className={` p-4 pl-64 pr-64 pt-64 w-full`} style={props.style || {}}>
                <Grid container className={"w-full"} direction={"row"}>
                    <div className={"flex flex-row justify-content-center align-middle w-full flex-wrap-reverse align-content-center"}>
                        <div className={"flex flex-column justify-content-center align-middle align-items-center w-full generic_box_max_width"} >
                            <div className={"m-8 flex flex-column"}>
                                <div className={'font-bold font_size_xl text_green_light_color'}>
                                    <h1>{homepageData.header_my_name.text}, psiholog atestat</h1>
                                </div>
                                <div className={'font-bold font_size_l text_lowercase'}>
                                    <h2>MEMBRU IN COLEGIUL PSIHOLOGILOR DIN ROMANIA</h2>
                                </div>
                            </div>
                            <div className={'m-8 generic_box_max_width'} >
                                <div
                                    dangerouslySetInnerHTML={{__html: prismicAboutme.big_description.html}}
                                />
                            </div>
                        </div>

                        <div className={""}>
                            <div style={{borderRadius: '20px'}} className={'m-8'}>
                                <ProfileSecondaryImage />
                            </div>
                        </div>
                    </div>
                </Grid>

                <div className={"w-full flex flex-row align-middle justify-center align-items-start mt-16 mb-8"}>
                    <h2 className={'font_size_xxl'}>
                        <b>Curriculum vitae Roxana-Anca Simionescu, Psiholog si Psihoterapeut</b>
                    </h2>
                </div>


                    <Grid container className={"w-full"} direction={"row"} spacing={1} alignContent={"center"} justifyContent={"center"}>
                        {aboutMeData.map((item, ik) => (
                            <Grid key={ik} item sm={12} lg={12} xl={12} md={12} className={"w-full flex flex-col justify-items-center align-top align-items-center"}>
                                <div className={"mb-8 mt-8 w-full flex flex-row justify-items-start align-top align-items-start"} style={{ maxWidth: '800px'}}>
                                    <FontAwesomeIcon
                                        icon={faCertificate}
                                        color={'#32a852'}
                                        className={"mr-4 list_icon"}
                                        title={item.title}
                                    />
                                    <span className={"font_size_xl"}><b>{item.title}</b></span>
                                    {/*<div className={"font_size_xl mb-8 mt-8"}></div>*/}
                                </div>
                                <div className={"w-full flex flex-col justify-items-start align-top align-items-start"} style={{ maxWidth: '800px'}}>
                                    {item.prismic.items.map((registry, rk) => (
                                        <div key={rk} className={"mt-2 flex flex-row w-full justify-items-start align-top align-items-start"}>
                                           <div>
                                               <FontAwesomeIcon
                                                   icon={faCheck}
                                                   color={'#32a852'}
                                                   className={"mr-4 list_icon"}
                                                   title={registry.element.text.toUpperCase()}
                                               />
                                           </div>
                                           <div>
                                               {registry.element.text.toUpperCase()}
                                           </div>
                                        </div>
                                    ))}
                                </div>
                            </Grid>
                        ))}
                    </Grid>
            </Grid>

            <Grid item sm={12} xl={12} lg={12} md={12} className={` p-4 pl-64 pr-64 pt-64 w-full flex flex-row justify-content-center align-middle w-full flex-wrap-reverse align-content-center`}>
                <OpenChat />
            </Grid>
        </React.Fragment>


    )
}

export default MainPresentationLargeBox
