import * as React from "react"
import {Grid} from "@material-ui/core";
import MainMenu from "../MainMenu";
import "./../../styles/index.scss"
import Seo from "../seo";
import MainPresentationLargeBox from "../MainPresentationLargeBox";
import AppFooter from "../AppFooter";
import {Mixpanel} from "../Mixpanel";
import {useEffect, useMemo} from "react";
import Banner from "../Banner";

export default function ComponentAbautMe ({data}) {
    const prismicContact = data.prismicContact
    const homepageData = data.prismicHomepage.data
    const prismicAboutme = data.prismicAboutme.data

    useEffect(() => {
        Mixpanel.track('Page-Aboutme');
    }, [data])

    const seoData = {
        canonical: "https://www.cabinet-psiho.ro/aboutme/",
        title: 'Despre mine - Cabinet psihologic sector 6',
        description: 'Roxana Simionescu psiholog atestat, Bucuresti, acreditat, psihoterapeut, membru in colegiul psihologilor, psihologie clinica, terapie de familie, psihoterapie',
        url: 'https://www.cabinet-psiho.ro/aboutme/',
        metas: [
        ]
    }

    const renderUsingMemo = () => (
        <div >
            <Seo title="Despre mine" seoData={seoData}/>
            <Grid container>
                <MainMenu pageName={"aboutme"} prismicContact={prismicContact} />

                <Banner />

                <MainPresentationLargeBox
                    homepageData={homepageData}
                    prismicAboutme={prismicAboutme}
                />

                <AppFooter prismicContact={prismicContact}/>
            </Grid>
        </div>
    )

    const memoizedValue = useMemo(() => renderUsingMemo(), [homepageData]);
    return (<>{memoizedValue}</>)
}